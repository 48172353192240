<template>
  <div class="estate_page relative-center noto">
    <div
      class="fixed w-100vw h-100vh search_loading flex-center z-2 t-0 l-0"
      v-show="loading"
    >
      <div>
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <div class="f-12 mt-5">読み込み中....</div>
      </div>
    </div>

    <div class="f-16 f-14-xxs mb-10">
      【{{ tradeType }}】{{ newOld }}{{ buildType }}&nbsp;検索
    </div>

    <v-row class="relative-center">
      <v-col
        cols="12"
        xl="8"
        lg="8"
        md="8"
        sm="12"
        order="2"
        order-xl="1"
        order-lg="1"
        order-md="1"
        order-sm="2"
        id="buken_top"
      >
        <div class="pt-2">
          <v-select
            v-model="list"
            :items="items"
            label="表示件数"
            outlined
            class="float-left mx-2 hyoji"
          ></v-select>

          <v-select
            v-model="sort"
            :items="sorts"
            label="並び替え"
            outlined
            class="float-left mx-2 sort"
          ></v-select>

          <div class="float-right kensu relative">
            <span class="relative pl-2">
              {{ (page - 1) * list + 1 }}～
              {{
                page * list > count ? count : page * list
              }}&nbsp;件表示&nbsp;&nbsp;/&nbsp;<span class="f-11 bold"
                >全&nbsp;<span class="f-red f-13">{{ count }}</span
                >&nbsp;件中</span
              >
            </span>
          </div>
          <div class="clear mb-2" />
        </div>

        <div class="dis-sm mb-8">
          <v-btn
            dark
            color="bg-orange btn50 float-left"
            @click="jokenOpen()"
            class="f-12 f-10-xxs py-6"
            >条件を絞り込む</v-btn
          >

          <v-btn
            dark
            color="bg-orange btn50 float-left"
            @click="$refs.City.dialog = true"
            class="f-12 f-10-xxs py-6"
            >エリアの選択</v-btn
          >
          <div class="clear" />
        </div>

        <div class="w400 relative-center">
          <v-pagination
            v-model="page"
            :length="Math.ceil(count / list)"
            v-show="lists.length > 0"
          />
        </div>

        <div class="nothing" v-show="lists.length == 0">
          該当する物件がございません
        </div>
        <router-link
          v-for="(es, i) in lists"
          :key="i"
          :to="{ path: '/estate/' + path + '/detail/' + es['th6'] }"
          target="_blank"
        >
          <div class="my-10 py-2 px-4 bukken_box relative">
            <div
              class="f-11 bold text-left py-2 pl-2 bukken_mei"
              v-if="es['th10'] != null"
              v-html="
                es['th10'].replace(/<*>/g, '').replace(/ *\([^)]*\) */g, '')
              "
            />
            <div
              class="new rounded absolute r-20 t-10 f-08"
              v-if="moment().isBefore(moment(es['th8']).add(4, 'days'))"
            >
              NEW
            </div>
            <div class="img_box bg-white float-left border">
              <div class="main_img mb-1">
                <img
                  :src="
                    imgs[imgs.findIndex(({ id }) => id === es['id'])][
                      'img' + num
                    ] != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])][
                          'img' + num
                        ]
                      : imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img1 != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])].img1
                      : imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img2 != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])].img2
                      : require('@/assets/img/noimage.jpg')
                  "
                  v-if="
                    imgs[imgs.findIndex(({ id }) => id === es['id'])] != null
                  "
                />
                <img :src="require('@/assets/img/noimage.jpg')" v-else />
              </div>
              <div class="sub_img float-left flex-center">
                <img
                  :src="
                    imgs[imgs.findIndex(({ id }) => id === es['id'])][
                      'img' + num + 1
                    ] != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])][
                          'img' + num + 1
                        ]
                      : imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img1 != null &&
                        imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img2 != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])].img2
                      : imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img1 == null &&
                        imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img3 != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])].img3
                      : require('@/assets/img/noimage.jpg')
                  "
                  v-if="
                    imgs[imgs.findIndex(({ id }) => id === es['id'])] != null
                  "
                />
                <img :src="require('@/assets/img/noimage.jpg')" v-else />
              </div>
              <div class="sub_img float-right flex-center">
                <img
                  :src="
                    imgs[imgs.findIndex(({ id }) => id === es['id'])][
                      'img' + num + 2
                    ] != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])][
                          'img' + num + 2
                        ]
                      : imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img1 != null &&
                        imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img3 != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])].img3
                      : imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img1 == null &&
                        imgs[imgs.findIndex(({ id }) => id === es['id'])]
                          .img4 != null
                      ? imgs[imgs.findIndex(({ id }) => id === es['id'])].img4
                      : require('@/assets/img/noimage.jpg')
                  "
                  v-if="
                    imgs[imgs.findIndex(({ id }) => id === es['id'])] != null
                  "
                />
                <img :src="require('@/assets/img/noimage.jpg')" v-else />
              </div>
              <div class="clear" />
            </div>

            <div class="my-3 dis-md" />

            <div class="bukken_info bg-white float-left border ml-2 text-left">
              <div class="px-2 pt-2">
                <div class="float-left info_title bold">
                  <span v-if="es['th16'] != null"> 価格 </span>
                  <span v-else> 賃料 </span>
                </div>
                <div class="float-left info_contents bold f-red f-12">
                  <span v-if="es['th16'] != null">
                    {{ Number(es["th16"]).toLocaleString() }}万円
                  </span>
                  <span v-else>
                    {{ Number(es["th17"]).toLocaleString() }}万円
                  </span>
                </div>
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>
              <div class="px-2 pt-2">
                <div class="float-left info_title bold f-09">所在地</div>
                <div class="float-left info_contents f-09">
                  <span
                    v-if="es['th10'] != null"
                    v-html="
                      es['th10']
                        .replace(/<*>/g, '')
                        .replace(/ *\([^)]*\) */g, '')
                    "
                  />
                </div>
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>
              <div class="px-2 pt-2">
                <div class="float-left info_title bold f-09">交通</div>
                <div class="float-left info_contents f-09">
                  <span
                    v-if="es['th13'] != null"
                    v-html="
                      es['th13']
                        .replace(/<*>/g, '')
                        .replace(/ *\([^)]*\) */g, '')
                    "
                  />
                </div>
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>

              <div class="px-2 pt-2" v-if="es['th36'] != null">
                <div class="float-left info_title bold f-09">階建</div>
                <div
                  class="float-left info_contents f-09"
                  v-html="es['th46']"
                />
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>

              <div class="px-2 pt-2" v-else>
                <div class="float-left content_border">
                  <div class="float-left info_title bold f-09">階建</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="es['th46']"
                  />
                  <div class="clear" />
                </div>

                <div class="float-left">
                  <div class="float-left info_title bold f-09">間取</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="es['th32']"
                  />
                  <div class="clear" />
                </div>
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>

              <div class="px-2 pt-2" v-if="es['th36'] != null">
                <div class="float-left content_border">
                  <div class="float-left info_title bold f-09">間取</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="es['th32']"
                  />
                  <div class="clear" />
                </div>

                <div class="float-left">
                  <div class="float-left info_title bold f-09">
                    <span v-if="tradeType == '売買'"> 専有面積 </span>
                    <span v-else> 使用面積 </span>
                  </div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="
                      es['th36'] != null
                        ? Math.round(es['th36'] * 1000) / 1000 + 'm&sup2;'
                        : '- '
                    "
                  />
                  <div class="clear" />
                </div>
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>

              <div class="px-2 pt-2" v-else>
                <div class="float-left content_border">
                  <div class="float-left info_title bold f-09">土地面積</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="
                      es['th37'] != null
                        ? Math.round(es['th37'] * 1000) / 1000 + 'm&sup2;'
                        : '- '
                    "
                  />
                  <div class="clear" />
                </div>

                <div class="float-left">
                  <div class="float-left info_title bold f-09">建物面積</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="
                      es['th39'] != null
                        ? Math.round(es['th39'] * 1000) / 1000 + 'm&sup2;'
                        : '- '
                    "
                  />
                  <div class="clear" />
                </div>
                <div class="clear" />
                <div class="info_border mt-2" />
              </div>

              <div class="pa-2">
                <div class="float-left content_border">
                  <div class="float-left info_title bold f-09">築年</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="moment(es['th35']).format('YYYY年MM月')"
                  />
                  <div class="clear" />
                </div>
                <div class="float-left">
                  <div class="float-left info_title bold f-09">建物構造</div>
                  <div
                    class="float-left info_contents_m f-09"
                    v-html="es['th48']"
                  />
                  <div class="clear" />
                </div>
                <div class="clear" />
              </div>
            </div>
            <div class="clear" />
            <div class="my-2 dis-md" />
          </div>
        </router-link>
        <div class="w400 relative-center" @click="pageTop">
          <v-pagination
            v-model="page"
            :length="Math.ceil(count / list)"
            v-show="lists.length > 0"
          ></v-pagination>
        </div>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        order="1"
        order-xl="2"
        order-lg="2"
        order-md="2"
        order-sm="1"
      >
        <div class="text-right pr-5 mb-3">
          <router-link to="/estate/buy/new/kodate" v-if="tradeType == '賃貸'">
            <div
              class="border-blue px-4 pa-2 rounded inline-block mb-4 f-black"
            >
              <span class="f-15 f-blue">売買</span>&nbsp;へ&nbsp;&nbsp;<span
                class="f-17 f-blue"
                >&#x27A1;</span
              >
            </div>
          </router-link>
          <router-link to="/estate/rent/sumai" v-else>
            <div
              class="border-orange px-4 pa-2 rounded inline-block mb-4 f-black"
            >
              <span class="f-15 f-orange">賃貸</span>&nbsp;へ&nbsp;&nbsp;<span
                class="f-17 f-orange"
                >&#x27A1;</span
              >
            </div>
          </router-link>
          <div class="my-4" />
          <div v-if="tradeType == '売買'">
            <div
              v-show="newOld != '新築' || buildType != '一戸建'"
              class="mb-1"
            >
              <router-link :to="'/estate/' + path + '/new/kodate'"
                >「新築一戸建」に切り替える</router-link
              >
            </div>
            <div
              v-show="newOld != '中古' || buildType != '一戸建'"
              class="mb-1"
            >
              <router-link :to="'/estate/' + path + '/used/kodate'"
                >「中古一戸建」に切り替える</router-link
              >
            </div>
            <div
              v-show="newOld != '中古' || buildType != 'マンション'"
              class="mb-1"
            >
              <router-link :to="'/estate/' + path + '/used/mansion'"
                >「中古マンション」に切り替える</router-link
              >
            </div>
          </div>
          <div v-else>
            <div v-show="buildType != '住まい'" class="mb-1">
              <router-link :to="'/estate/' + path + '/sumai'"
                >「住まい」に切り替える</router-link
              >
            </div>
            <div v-show="buildType != '店舗・事務所'" class="mb-1">
              <router-link :to="'/estate/' + path + '/store'"
                >「店舗・事務所」に切り替える</router-link
              >
            </div>
          </div>
        </div>
        <div :class="{ noevent: loading2, opacity05: loading2 }">
          <Narrow
            ref="Narrow"
            @priceChange="priceChange"
            @floorChange="floorChange"
            @ownerChange="ownerChange"
            @emptyChange="emptyChange"
            @area1Change="area1Change"
            @frameChange="frameChange"
            @yearChange="yearChange"
            @dialogOpen="dialogOpen"
            v-show="jokenShow"
            id="joken"
            :City="city"
          />
        </div>
      </v-col>
    </v-row>

    <City ref="City" @cityChange="cityChange" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import Narrow from "@/components/parts/narrow.vue";
import City from "@/components/parts/city.vue";
Vue.prototype.moment = moment;

import CityMix from "@/mixins/city";
import NarrowMix from "@/mixins/narrow";

export default {
  mixins: [CityMix, NarrowMix],
  data() {
    return {
      lists: [],
      imgs: [],
      count: 0,
      list: 10,
      page: 1,
      items: [10, 20, 50],
      sort: "新着・更新順",
      sorts: [],
      loading: 1,
      jokenShow: true,
      tradeType: "",
      newOld: "",
      buildType: "",
      path: "",
      num: 5,
      loading2: 1,
    };
  },
  components: {
    Narrow,
    City,
  },

  async mounted() {
    if (window.innerWidth < 960) {
      this.jokenShow = false;
    }
    this.routeChange();
  },

  methods: {
    routeChange() {
      if (this.$route.path.indexOf("/buy") != -1) {
        this.tradeType = "売買";
        this.path = "buy";
        this.num = 5;
      } else {
        this.tradeType = "賃貸";
        this.path = "rent";
        this.num = 6;
      }
      if (this.$route.path.indexOf("/new") != -1) {
        this.newOld = "新築";
      } else if (this.$route.path.indexOf("/used") != -1) {
        this.newOld = "中古";
      }
      if (this.$route.path.indexOf("/mansion") != -1) {
        this.buildType = "マンション";
        this.sorts = [
          "新着・更新順",
          "価格が安い順",
          "価格が高い順",
          "面積が広い順",
          "間取り順",
          "築年が新しい順",
        ];
      } else if (this.$route.path.indexOf("/kodate") != -1) {
        this.buildType = "一戸建";
        this.sorts = [
          "新着・更新順",
          "価格が安い順",
          "価格が高い順",
          "面積が広い順",
          "間取り順",
          "築年が新しい順",
        ];
      } else if (this.$route.path.indexOf("/sumai") != -1) {
        this.buildType = "住まい";
        this.sorts = [
          "新着・更新順",
          "賃料が安い順",
          "賃料が高い順",
          "面積が広い順",
          "間取り順",
          "築年が新しい順",
        ];
      } else if (this.$route.path.indexOf("/store") != -1) {
        this.buildType = "店舗・事務所";
        this.sorts = [
          "新着・更新順",
          "賃料が安い順",
          "賃料が高い順",
          "面積が広い順",
          "間取り順",
          "築年が新しい順",
        ];
      }
      this.listChange();
    },
    async pageChange() {
      this.loading = 1;
      const data = {
        type: this.type,
        tradeType: this.tradeType,
        newOld: this.newOld,
        buildType: this.buildType,
        sort: this.sort,
        bPrice: this.bPrice,
        tPrice: this.tPrice,
        floor: JSON.stringify(this.floor),
        empty: this.empty,
        area1: this.area1,
        frame: JSON.stringify(this.frame),
        year: this.year,
        city: JSON.stringify(this.city),
        page: this.page,
        list: this.list,
      };
      const response = await axios.post("/estate/search_load", data);
      this.lists = response.data.estates;
      this.imgs = response.data.imgs;
      this.count = response.data.count;
      this.loading = 0;
    },
    async listChange() {
      this.lists = [];
      this.$refs.Narrow.roomCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.$refs.Narrow.emptyCount = 0;
      this.$refs.Narrow.frameCount = [0, 0, 0, 0];

      if (this.bPrice > this.tPrice) {
        return;
      }
      this.loading = 1;
      this.loading2 = 1;
      if (this.page != 1) {
        this.page = 1;
      }
      await this.pageChange();

      const data = {
        type: this.type,
        tradeType: this.tradeType,
        newOld: this.newOld,
        buildType: this.buildType,
        sort: this.sort,
        bPrice: this.bPrice,
        tPrice: this.tPrice,
        floor: JSON.stringify(this.floor),
        empty: this.empty,
        area1: this.area1,
        frame: JSON.stringify(this.frame),
        year: this.year,
        city: JSON.stringify(this.city),
        page: this.page,
        list: this.list,
      };
      const response = await axios.post("/estate/all_load", data);
      this.$refs.Narrow.roomCount = [
        response.data.room0,
        response.data.room1,
        response.data.room2,
        response.data.room3,
        response.data.room4,
        response.data.room5,
        response.data.room6,
        response.data.room7,
        response.data.room8,
        response.data.room9,
        response.data.room10,
        response.data.room11,
        response.data.room12,
        response.data.room13,
      ];
      this.$refs.Narrow.emptyCount = response.data.emptyCount;

      this.$refs.Narrow.frameCount = [
        response.data.rcCount,
        response.data.srcCount,
        response.data.woodCount,
        this.count -
          response.data.rcCount -
          response.data.srcCount -
          response.data.woodCount,
      ];
      this.loading2 = 0;
    },
    pageTop() {
      this.jamp("buken_top");
    },
    priceChange(b, t) {
      this.bPrice = b;
      this.tPrice = t;
      this.listChange();
    },
    floorChange(val) {
      this.floor = val;
      this.listChange();
    },
    ownerChange(val) {
      this.owner = val;
      this.listChange();
    },
    emptyChange(val) {
      this.empty = val;
      this.listChange();
    },
    area1Change(val) {
      this.area1 = val;
      this.listChange();
    },
    frameChange(val) {
      this.frame = val;
      this.listChange();
    },
    yearChange(val) {
      this.year = val;
      this.listChange();
    },
    cityChange(val) {
      this.city = val;
      this.reset();
      this.$refs.Narrow.reset();
      this.listChange();
    },
    dialogOpen() {
      this.$refs.City.dialog = true;
    },
    jokenOpen() {
      this.jokenShow = true;
      this.jamp("joken");
    },
    jamp(val) {
      let element = document.getElementById(val);
      let rect = element.getBoundingClientRect();
      let elemtop = rect.top + window.pageYOffset;
      document.documentElement.scrollTop = elemtop;
    },
  },

  watch: {
    page() {
      this.pageChange();
    },
    list() {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.pageChange();
      }
    },
    sort() {
      this.listChange();
    },

    async $route() {
      this.newOld = "";
      this.reset();
      this.routeChange();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/estate/index.scss";
@import "@/assets/sass/estate/search.scss";
</style>

