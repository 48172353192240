

export default {
    data() {
        return {
            city: [],
        }
    },
    created() {
        this.cityReset()
    },

    methods: {
        cityReset() {
            let city = [];
            for (let i = 0; i < 72; i++) {
                city[i] = false;
            }
            this.city = city;
        }
    },

    watch: {
        async $route() {
            this.cityReset()
        },
    }
}