

export default {
    data() {
        return {
            tPrice: 10000000000,
            bPrice: 0,
            floor: [],
            owner: false,
            empty: false,
            area1: 0,
            area2: 0,
            area3: 0,
            frame: [],
            year: 0,
            type: "",
        }
    },
    created() {
        this.arrayReset()
    },
    methods: {
        arrayReset() {
            let floor = []
            for (let i = 0; i < 13; i++) {
                floor[i] = false
            }
            this.floor = floor

            let frame = []
            for (let i = 0; i < 3; i++) {
                frame[i] = false
            }
            this.frame = frame
        },
        reset(){
            
      this.arrayReset();
      this.tPrice = 10000000000;
      this.bPrice = 0;
      this.owner = false;
      this.empty = false;
      this.area1 = 0;
      this.year = 0;
        }
    }
}